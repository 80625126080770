import React, { useEffect } from 'react'
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor'
import Layout from 'components/Layout';
import SectionsWrapper from 'components/SectionsWrapper';
import AccountTypesNav from 'components/AccountTypesNav';
import SectionDivider from 'components/SectionDivider';
import ReadyToTrade from 'components/ReadyToTrade';
import Hero from 'components/Hero';
import Section from 'components/Section';
import TastyButton from 'components/TastyButton';
import accountsHero from 'images/accounts-hero.png';
import { useLayoutContext } from 'context/LayoutContext';
import externalLinks from 'utils/externalLinks';
import SEO from 'components/SEO';
import TitleHeader from '../components/TitleHeader';

const AccountSectionsWrapper = styled(SectionsWrapper)`
  & > h1 {
    color: ${props => props.theme.colors.black};
    font-weight: bold;
    font-size: 3.5em;
    line-height: normal;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;
  }

  &&& {
    p {
      font-weight: 300;
    }
  }

  hr {
    border-top: 1px solid ${props => props.theme.colors.darkGrayscaleGreen};
  }

  ol {
    font-family: 'Open Sans';
    padding-left: 1em;

    li {
      margin-bottom: 0.5em;
    }
  }

  &:first-of-type {
    section {
      &:nth-child(7) {
        padding-bottom: 3rem;

        @media (min-width: ${props => props.theme.breakpoints.md}) {
          padding-bottom: 5rem;
        }
      }
    }
  }

  ${ifProp(
    'unavailable',
    css`
      background-color: ${props => props.theme.colors.lighterGray};
      padding-bottom: 3rem;
      padding-top: 3rem;

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        padding-bottom: 5rem;
        padding-top: 5rem;
      }
    `
)}

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    ${TastyButton}  {
      width: 100%;
    }
  }
`;

const ComingSoonHeader = styled.h2`
  font-weight: bold;
  font-size: 3.5em;
  line-height: normal;
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;
`;

const TheWorksSection = styled(Section)`
  padding-top: 5em;
  margin: 0 auto;

  ${TastyButton}  {
    margin-bottom: 3.5em;

    & + p {
      margin-bottom: 0;
    }

    & + p,
    & + p + p {
      font-size: 0.9em;
    }
  }

  span {
    font-weight: 700;
  }

  ul {
    font-family: 'Open Sans';
    font-size: 0.9em;
  }

  ul.the-works {
    font-size: 0.9em;
    list-style-type: none;
    margin-bottom: 4em;
    padding-left: 0rem;

    li {
      font-weight: bold;
      margin-bottom: 0.25em;

      .fa-circle {
        color: ${props => props.theme.colors.primaryRed};
        font-size: 0.75em;
        margin-right: 1em;
        vertical-align: baseline;
      }
    }

    ul {
      font-size: 1em;
      margin-bottom: 0;
      list-style-type: none;
      padding-left: 1.5625rem;

      li {
        font-weight: normal;

        &:before {
          content: '\\2022';
          margin-right: 0.625rem;
        }
      }
    }
  }
`;

const WorksTitle = styled.h2`
  font-size: 3em;
  text-transform: uppercase;
  color: ${props => props.theme.colors.primaryRed};
`;

const Account = styled.div`
  &&& {
    .h5,
    p {
      font-family: 'Open Sans';
      font-size: 0.875rem;
      font-weight: 300;
    }

    .h5 {
      font-weight: bold;
    }

    p {
      margin-bottom: 1.5em;
    }
  }

  ${ifProp(
    'custodial',
    css`
      margin-top: 1.875rem;
    `
  )}
`;

function AccountsPage({ showPromoBar }) {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  useEffect(() => {
    configureAnchors({offset: -140, scrollDuration: 600});
  })

  return (
    <Layout>
      <SEO
        title='Account Types'
        description='tastytrade offers a wide variety of brokerage accounts. We offer margin accounts, cash accounts, and retirement accounts for traders.'
        canonicalHref='https://tastytrade.com/accounts'
      />
      <main>
        <Hero
          heroImage={accountsHero}
          $showAlert={showAlert}
          page='accounts'
          showPromoBar={showPromoBar}
        >
          <h1>Account Types</h1>
          <h2>Freedom to trade boldly</h2>
        </Hero>
        <AccountTypesNav showPromoBar={showPromoBar} />
        <AccountSectionsWrapper noGutters>
          <TheWorksSection withPadding>
            <WorksTitle>The Works</WorksTitle>
            <p>
              Our most flexible account package for active retail traders is called ‘The
              Works’.
            </p>
            <ul className='the-works'>
              <li>
                <FontAwesomeIcon icon='circle' />
                Individual margin account
              </li>
              <li>
                <FontAwesomeIcon icon='circle' />
                Access to trade all available products:
                <ul>
                  <li>Stocks</li>
                  <li>Covered and uncovered options</li>
                  <li>Covered and uncovered option spreads</li>
                  <li>Futures</li>
                  <li>Options on futures</li>
                </ul>
              </li>
              <li>
                <FontAwesomeIcon icon='circle' />
                Access to use any options trading strategy available on the tastytrade
                platform
              </li>
              <li>
                <FontAwesomeIcon icon='circle' />
                Portfolio Margin available for accounts with appropriate suitability. {' '}
                <a href={externalLinks.portfolioMargin} target='_blank' rel='noopener noreferrer'>Learn More.</a>
                {' '}
              </li>
            </ul>
            <TastyButton primary href={externalLinks.openAnAccount}>I Want The Works</TastyButton>
            <p>The application should take you <span>3-5 minutes</span> to complete.</p>
            <p>
              Before you get started, make sure you have the following for each
              applicant:
            </p>
            <ul>
              <li>Individual Tax Identification Number</li>
              <li>Email address</li>
              <li>A physical mailing address</li>
              <li>Full name of each applicant</li>
            </ul>
          </TheWorksSection>
          <Section withPadding>
            <ScrollableAnchor id='individual'>
              <SectionDivider />
            </ScrollableAnchor>
            <TitleHeader>Individual</TitleHeader>
            <p>
              Our standard trading account for an individual. Select either a Margin
              or Cash Account:
            </p>
            <Account>
              <h3 className='h5'>Margin</h3>
              <p>
                A margin account is a brokerage account in which the broker lends
                the customer cash to purchase stocks or other financial products.
                The loan in the account is collateralized by the securities purchased
                and cash, and comes with a periodic interest rate. Have a look at our{' '}
                <a href={externalLinks.marginDisclosure} target='_blank' rel='noopener noreferrer'>Margin Disclosure</a>
                {' '}to understand the risks associated with trading on margin.
              </p>
            </Account>
            <hr />
            <Account>
              <h3 className='h5'>Cash</h3>
              <p>
                A cash account does not have margin benefits. That means you must
                be able to cover your trade&apos;s full purchase price with the money
                already in your account.
              </p>
            </Account>
          </Section>
          <Section withPadding>
            <ScrollableAnchor id='joint'>
              <SectionDivider />
            </ScrollableAnchor>
            <TitleHeader>Joint</TitleHeader>
            <p>
              Our standard trading account for two people and can be a margin or
              cash account.  Select the type of account to fit your financial goals.
            </p>
            <Account>
              <h3 className='h5'>Tenants in Common (TIC)</h3>
              <p>
                A joint account type in which two owners each have a specific
                proportion of the account&apos;s assets. When one account owner dies,
                their assets are passed on to their estate.
              </p>
            </Account>
            <hr />
            <Account>
              <h3 className='h5'>With Rights of Survivorship (WROS)</h3>
              <p>
                A joint account type in which two owners have equal share of assets.
                When one owner dies, the surviving owner will have full rights to
                the account.
              </p>
            </Account>
          </Section>
          <ReadyToTrade />
        </AccountSectionsWrapper>
        <AccountSectionsWrapper unavailable noGutters>
          <ComingSoonHeader>Coming Soon!</ComingSoonHeader>
          <Section withPadding>
            <Account>
              <h3 className='h5'>Custodial</h3>
            </Account>
            <Account custodial>
              <h3 className='h5'>UGMA/UTMA</h3>
              <p>
                Custodial account types established by an adult who is the custodian
                on behalf of a minor who is the beneficiary. The assets are
                transferred to the beneficiary once they are no longer considered a
                minor.
              </p>
            </Account>
            <hr />
            <Account>
              <h3 className='h5'>Coverdell</h3>
              <p>
                Established as a type of savings plan intended to contribute funds
                towards a student&apos;s qualified educational expenses. Contributions
                may be made for individuals who are under the age of 18. Qualified
                distributions are not taxable at the state or federal level.
              </p>
            </Account>
          </Section>
          <Section withPadding>
            <ScrollableAnchor id='corporate'>
              <SectionDivider />
            </ScrollableAnchor>
            <Account>
              <h3 className='h5'>Company</h3>
              <p>
                Corporate accounts are opened on behalf of a legally established Australian entity.
              </p>
            </Account>
          </Section>
          <Section withPadding>
            <ScrollableAnchor id='trust'>
              <SectionDivider />
            </ScrollableAnchor>
            <Account>
              <h3 className='h5'>Trust</h3>
              <p>
                A trust account is an account type owned and managed by either the individual
                trustee or trustees, or corporate trustees. The Trust Deed must be provided at
                account opening.
              </p>
            </Account>
          </Section>
          <Section withPadding>
            <ScrollableAnchor>
              <SectionDivider />
            </ScrollableAnchor>
            <Account>
              <h3 className='h5'>Self-Managed Super Fund</h3>
              <p>
                A Self-Managed Super Fund (SMSF) is an account type owned
                and managed by either the individual trustee or trustees, or corporate trustees.
                This allows you to take full direct control over how your retirement savings are managed.
              </p>
            </Account>
          </Section>
        </AccountSectionsWrapper>
      </main>
    </Layout>
  )
};

export default AccountsPage;
